import CloseButton from 'Atoms/Buttons/CloseButton';
import { SearchIcon } from 'Atoms/Icons';
import { useEffect, useState } from 'react';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import { CSS } from '@stitches/react';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';

type PropTypes = {
  doSearch: () => void;
  query: string;
  setQuery: (value: string) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  css?: CSS;
  placeholder?: string;
};

function SearchInput({ doSearch, query, setQuery, inputRef, css }: PropTypes) {
  const { commonLabels } = useTranslationData();
  const [hasValue, setHasValue] = useState<boolean>(false);
  const isMobile = useMedia(mediaQueryTypes.mediaMaxMedium);
  const { pageType, pageId } = useCurrentPage();

  const clearInput = () => {
    setQuery('');
    setHasValue(false);
  };
  useEffect(() => {
    query !== '' && setHasValue(true);
  }, [query]);

  useEffect(() => {
    if (pageType !== 'SearchPage') {
      setQuery('');
    }
  }, [pageId, setQuery, pageType]);

  return (
    <Container
      hasValue={hasValue}
      css={css}
      onClick={() => {
        inputRef && inputRef.current?.focus();
      }}
    >
      <InputField
        autoFocus={isMobile}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          setHasValue(!!e.currentTarget.value);
          setQuery(e.currentTarget.value);
        }}
        onKeyDown={(e) => e.key === 'Enter' && doSearch()}
        ref={inputRef}
        value={query}
        type="input"
        placeholder={commonLabels.search}
      />
      {query !== '' && (
        <CloseButton size="m" bgColor="primary" onClick={clearInput} />
      )}
      <SearchButton aria-label={'search button'}>
        <StyledSearchIcon onClick={doSearch} color="secondary" />
      </SearchButton>
    </Container>
  );
}

export default SearchInput;

const Container = styled('div', {
  h: 10,
  display: 'flex',
  alignItems: 'center',
  py: 3,
  px: 6,
  w: '100%',
  maxW: 'unset',
  position: 'relative',
  borderWidth: '1px',
  borderColor: '$quickSearchOutline',
  borderRadius: '100px',
  color: '$quickSearchTextPrimary',
  backgroundColor: '$primary4',
  '&:focus-within': {
    borderColor: '$mobileSearchBorderPrimary',
    color: '$mobileSearchBorderPrimary',
  },
  'input[type="search"].with-appearance-none': {
    WebkitAppearance: 'none',
  },
  '@mediaMinLarge': {
    maxW: 104,
  },
  variants: {
    hasValue: {
      false: {
        borderColor: '$quickSearchOutlineNoValue',
      },
    },
  },
});

const SearchButton = styled('button', {
  ml: 4,
  cursor: 'pointer',
});

const StyledSearchIcon = styled(SearchIcon, {
  w: 4,
  h: 4,
  mr: 2,
  l: 0,
});

const InputField = styled('input', {
  h: '100%',
  color: '$primaryLight1',
  backgroundColor: 'transparent',
  flexGrow: 1,
  flexShrink: 1,
  '&:focus': { outline: 'none' },
  '&::-webkit-search-cancel-button': {
    WebkitAppearance: 'none',
  },
  fs: 8,
  lineHeight: '$lh15',
  '@mediaMinLarge': {
    fs: 6,
    lineHeight: '$lh133',
  },
  '&::placeholder': {
    color: '$primaryLight2',
  },
});

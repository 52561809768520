import { EmailIcon, SupportIcon } from 'Atoms/Icons';
import IconLink from 'Atoms/Links/IconLink';
import { H3 } from 'Atoms/Typography/Headings/Heading';
import useMedia from 'Shared/Hooks/useMedia';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useUserStateData } from '../../Shared/Providers/UserContextProvider';

type PropTypes = {
  query: string;
  suggestions: string[];
  setQuery: (value: string) => void;
};

function NoResult({ query, suggestions, setQuery }: PropTypes) {
  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);

  const { accountDispatch } = useUserStateData();

  const {
    searchLabels: { noHits, didYouMean },
  } = useTranslationData();
  const {
    searchSettings: {
      noResultText,
      noResultPhone,
      noResultEmail,
      noResultBullets,
    },
  } = useAppSettingsData();

  return (
    <MainContainer>
      <H3>{`${noHits} ${query}`}</H3>
      <List>
        {noResultBullets &&
          noResultBullets.map((item: string, index: number) => (
            <ListItem key={index + item}>{item}</ListItem>
          ))}
      </List>
      {suggestions && suggestions.length > 0 && (
        <>
          {<DidYouMeanText>{didYouMean}</DidYouMeanText>}
          {suggestions.map((item, index) => (
            <IconLink
              key={item + index}
              css={{
                fontFamily: isMobile ? '$fontPrimary400' : '$fontPrimary500',
                textTransform: 'uppercase',
                mb: 2,
              }}
              type="Arrow"
              onClick={() => accountDispatch({ type: 'setQuery', query: item })}
            >
              {item}
            </IconLink>
          ))}
        </>
      )}
      <NoResultText>{noResultText}</NoResultText>
      <IconList>
        <IconItem href={`tel:${noResultPhone}`}>
          <SupportIcon color="primary" size="m" />
          <ContactInfo>{noResultPhone}</ContactInfo>
        </IconItem>
        <IconItem href={`mailto:${noResultEmail}`}>
          <EmailIcon color="primary" size="m" />
          <ContactInfo>{noResultEmail}</ContactInfo>
        </IconItem>
      </IconList>
    </MainContainer>
  );
}

const MainContainer = styled('div', {
  color: '$pureWhite',
  display: 'flex',
  flexDirection: 'column',
  m: 'auto',
  maxW: 160,
  justifyContent: 'center',
  backgroundColor: '$noResultBackgroundColor',
  p: 4,
  pb: 20,
  '@mediaMinMedium': {
    p: 8,
  },
});

const List = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'unset',
  pl: 6,
});

const DidYouMeanText = styled('p', { fs: 8, mb: 2 });

const NoResultText = styled('p', { mt: 12, lineHeight: '$lh15' });

const ListItem = styled('li', { lineHeight: '$lh15' });

const IconList = styled(List, {
  flexDirection: 'Row',
  listStyle: 'none',
  pl: 0,
  mt: 4,
});

const IconItem = styled('a', {
  display: 'flex',
  mr: 12,
  lineHeight: '$lh15',
});

const ContactInfo = styled('p', {
  ml: 4,
  lineHeight: '$lh15',
});

export default NoResult;

import KexIconLoader, { LoadableIcons } from 'Kex/KexIconLoader';
import KexLink from 'Kex/KexLink';
import { useState } from 'react';
import {
  animateUnderlineActive,
  animateUnderlineNotActive,
} from 'Shared/Common/animateUnderline';
import { styled } from 'stitches.config';
type PropTypes = {
  whiteText?: boolean;
  underline?: boolean;
  onClick?: (x?: () => void) => void;
  href?: string;
  children: string | undefined;
  focused?: true;
  color?: string;
  type?: keyof typeof LoadableIcons;
};

function IconLink({
  whiteText = false,
  onClick,
  href,
  children,
  focused,
  color,
  type,
  ...rest
}: PropTypes) {
  const [linkIsHovered, setLinkIsHovered] = useState(false);
  const Icon = KexIconLoader(type ?? 'Arrow');
  return (
    <StyledLinkWrapper
      onMouseOver={() => setLinkIsHovered(true)}
      onMouseLeave={() => setLinkIsHovered(false)}
    >
      <StyledKexLink
        {...rest}
        onClick={onClick}
        href={href}
        fontFamily={'primary400'}
        underlined={false}
      >
        <Text linkIsHovered={linkIsHovered} data-whitetext={whiteText}>
          {children}
        </Text>
        <IconWrapper
          data-linkhovered={linkIsHovered}
          data-whitetext={whiteText}
        >
          <Icon size={'m'} color={'primary'} rotateLeft />
        </IconWrapper>
      </StyledKexLink>
    </StyledLinkWrapper>
  );
}

const StyledKexLink = styled(KexLink, {
  width: 'fit-content',
  display: 'flex',
});

const IconWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  m: 'auto',
  ml: 2,
});

const StyledLinkWrapper = styled('div', {
  width: 'fit-content',
});

const Text = styled('span', {
  m: 'auto',
  '&:nth-child(n)[data-whitetext="true"]': { color: '$pureWhite' },
  ...animateUnderlineNotActive,
  variants: {
    linkIsHovered: {
      true: {
        ...animateUnderlineActive,
      },
    },
  },
});

export default styled(IconLink);

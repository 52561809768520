import KexIconLoader from 'Kex/KexIconLoader';
import { styled } from 'stitches.config';
import { timings, animation } from 'Theme/Settings/animation';

type PropTypes = {
  code: string;
  maxQuantity: number;
  minQuantity: number;
  currentQuantity: number;
  handleIncrement: (code: string) => void;
  handleDecrement: (code: string) => void;
  size?: 'xs' | 's' | 'l';
  disabled?: boolean;
  disabledMin?: boolean;
};

function QuantitySelector({
  code,
  maxQuantity,
  minQuantity,
  currentQuantity,
  size,
  disabled,
  disabledMin,
  handleIncrement,
  handleDecrement,
}: PropTypes) {
  const hasReachedMaxQuantity = currentQuantity === maxQuantity;
  const hasReachedMinQuantity = currentQuantity === minQuantity;

  const SubtractIcon = KexIconLoader('Subtract');
  const AddIcon = KexIconLoader('Add');

  return (
    <QuantityWrapper>
      <IconWrapper
        aria-label={'Subtract button'}
        onClick={() => handleDecrement(code)}
        disabled={hasReachedMinQuantity || disabled || disabledMin}
        size={size}
      >
        <SubtractIcon color={'secondary'} />
      </IconWrapper>
      <CurrentQuantityWrapper size={size}>
        {currentQuantity}
      </CurrentQuantityWrapper>
      <IconWrapper
        aria-label={'Increment button'}
        onClick={() => handleIncrement(code)}
        disabled={hasReachedMaxQuantity || disabled}
        size={size}
      >
        <AddIcon color={'secondary'} />
      </IconWrapper>
    </QuantityWrapper>
  );
}

const BaseQuantityStyle = {
  backgroundColor: 'rgba(255,255,255, 0.0263)',
  color: '$secondary2',
  lineHeight: '$lh125',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid transparent',
  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
      },
    },
    size: {
      xs: {
        minW: 8,
        h: 8,
      },
      s: {
        minW: 8,
        h: 10,
      },
      l: {
        minW: 12,
        h: 12,
      },
    },
  },
  defaultVariants: {
    size: 'xs',
  },
};

const QuantityWrapper = styled('div', {
  display: 'flex',
});

const CurrentQuantityWrapper = styled('div', {
  ...BaseQuantityStyle,
  cursor: 'default',
  mx: 0.5,
});

const IconWrapper = styled('button', {
  ...BaseQuantityStyle,
  transition: `all ${timings.oneFifth} ${animation.timingFn}`,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(255,255,255, 0.075)',
  },
});

export default QuantitySelector;

import KexCartModel from 'Models/Cart/KexCartModel.interface';
import { useEffect, useState } from 'react';
import { EventDispatcher, NOTIFY_ACTION } from 'Shared/Common/EventDispatcher';
import Fetcher from 'Shared/Common/Fetcher';
import { IS_PRODUCTION_ENV } from 'Shared/Configs/EnvConfig';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import useSWR, { mutate } from 'swr';
import { ProductType } from '../../Enums/ProductType.enum';

let abortController: AbortController = new AbortController();
let hasMounted = false;
export let cartUrl = '/api/cart/';

type CartLoading = {
  cart: KexCartModel | null;
  isLoading: true;
  isCartEmpty: true;
  hasMotorCycle: false;
  triggerRevalidation: () => void;
};

type CartNotLoading = {
  cart: KexCartModel;
  isLoading: false;
  isCartEmpty: boolean;
  hasMotorCycle: boolean;
  triggerRevalidation: () => void;
};

type CartReturnType = CartLoading | CartNotLoading;

export function GetCart(languageRoute: string): CartReturnType {
  const [returnType, setReturnType] = useState<CartReturnType>({
    cart: null,
    isLoading: true,
    isCartEmpty: true,
    hasMotorCycle: false,
    triggerRevalidation: () => {},
  });
  const { pageCacheTime } = useAppSettingsData();
  const { data: fetchedCart, mutate } = useSWR(
    `${cartUrl}GetCart?language=` + languageRoute,
    (url) => {
      return FetchCart(url);
    },
    {
      fallbackData: undefined,
      revalidateOnFocus: IS_PRODUCTION_ENV,
      dedupingInterval: pageCacheTime,
    }
  );

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (fetchedCart) {
        setReturnType({
          cart: fetchedCart,
          isLoading: false,
          isCartEmpty: fetchedCart.numberOfItems === 0,
          hasMotorCycle: !!fetchedCart.lineItems?.some(
            (item) => item.productType === ProductType.MOTORCYCLES
          ),
          triggerRevalidation: () => mutate(fetchedCart),
        });
      }
    }
  }, [fetchedCart, mutate]);

  return returnType;
}

export async function RemoveFromCart(
  productCode: string,
  languageRoute: string
) {
  const queryParams = {
    language: languageRoute,
    code: productCode,
  };

  const queryString = new URLSearchParams(queryParams).toString();

  const res = await fetch(`${cartUrl}RemoveItem?${queryString}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });

  if (res.ok) {
    const { cart, notification } = await res.json();
    EventDispatcher.dispatch(NOTIFY_ACTION, notification);
    mutate(`${cartUrl}GetCart?language=` + languageRoute, cart, false);
  }
}

export async function UpdateCart(
  productCode: string,
  quantity: number,
  languageRoute: string
) {
  const queryParams = {
    language: languageRoute,
    code: productCode,
    quantity: quantity.toString(),
  };

  const queryString = new URLSearchParams(queryParams).toString();

  const res = await fetch(`${cartUrl}Update?${queryString}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  });
  if (res.ok) {
    const { cart, notification } = await res.json();
    EventDispatcher.dispatch(NOTIFY_ACTION, notification);
    mutate(`${cartUrl}GetCart?language=` + languageRoute, cart, false);
    return true;
  }
  return false;
}

export async function SetQuantity(
  productCode: string,
  quantity: number,
  languageRoute: string,
  belongsTo?: string
) {
  let queryParams: { [key: string]: string } = {
    language: languageRoute,
    code: productCode,
    quantity: quantity.toString(),
  };

  if (belongsTo) {
    queryParams = { ...queryParams, belongsTo };
  }

  const queryString = new URLSearchParams(queryParams).toString();

  const res = await fetch(`${cartUrl}SetQuantity?${queryString}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
  if (res.ok) {
    const { cart, notification } = await res.json();
    EventDispatcher.dispatch(NOTIFY_ACTION, notification);
    mutate(`${cartUrl}GetCart?language=` + languageRoute, cart, false);
  }
}

export async function UpdateQuantity(
  productCode: string,
  quantity: number,
  languageRoute: string,
  belongsTo?: string
) {
  let queryParams: { [key: string]: string } = {
    language: languageRoute,
    code: productCode,
    quantity: quantity.toString(),
  };

  if (belongsTo) {
    queryParams = { ...queryParams, belongsTo };
  }

  const queryString = new URLSearchParams(queryParams).toString();

  const res = await fetch(`${cartUrl}Update?${queryString}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  });
  if (res.ok) {
    const { cart, notification } = await res.json();
    EventDispatcher.dispatch(NOTIFY_ACTION, notification);
    mutate(`${cartUrl}GetCart?language=` + languageRoute, cart, false);
  }
}

export async function EmptyCart(languageRoute: string) {
  mutate(`${cartUrl}GetCart`, { numberOfItems: 0 } as KexCartModel, false);

  const res = await fetch(`${cartUrl}RemoveAllItems`, {
    method: 'DELETE',
  });

  if (res.ok) {
    const { cart, notification } = await res.json();

    EventDispatcher.dispatch(NOTIFY_ACTION, notification);

    mutate(`${cartUrl}GetCart?language=` + languageRoute, cart, false);
  }
}

export async function FetchCartAndNotifyAll(languageRoute: string) {
  const res = await fetch(`${cartUrl}GetCart?language=` + languageRoute, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (res.ok) {
    const { cart } = await res.json();
    mutate(`${cartUrl}GetCart?language=` + languageRoute, cart, false);
  }
}

export async function ApplyMountingCost(
  languageRoute: string,
  isApply: boolean
) {
  const res = await fetch(
    `${cartUrl}ApplyMountingCosts?cartName=Default&apply=${isApply}&language=${languageRoute}`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    }
  );

  if (res.ok) {
    const { cart } = await res.json();
    mutate(`${cartUrl}GetCart?language=` + languageRoute, cart, false);
  }
}

function FetchCart(url: string) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;

  return Fetcher<KexCartModel, any>(url, signal, (data, resolve) => {
    resolve(data.cart);
  });
}

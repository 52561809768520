import ProductCardModel from 'Models/ProductCard/ProductCardModel.interface';
import VendorSparepartCard from 'Organisms/ProductCard/VendorSparepartCard';
import ProductCard from 'Organisms/ProductCard/ProductCard';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { canUseDOM, getUrlParameter } from 'Shared/Common/Helpers';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { styled } from 'stitches.config';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import ContentResult from './ContentResult';
import { SearchTypes, useSearch } from './Search';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import {
  FilterProvider,
  useFilterData,
} from '../../Organisms/FilterComponent/FilterProvider';
import SearchInput from '../../Organisms/QuickSearch/SearchInput';
import FilterBar from '../../Organisms/FilterComponent/FilterBar';
import NoResult from '../../Molecules/NoResult/NoResult';
import LoadMoreContainer from '../../Molecules/LoadMoreContainer/LoadMoreContainer';
import { useKexLoadingCircle } from '../../Kex/KexLoadingCircle';
import { ProductType } from '../../Enums/ProductType.enum';

const SearchWrapper = () => {
  if (!canUseDOM()) {
    return <></>;
  }

  return (
    <FilterProvider urlSearchString={window?.location.search || ''}>
      <SearchPage />
    </FilterProvider>
  );
};

const SEARCH_LOADING = 'SearchPage[Load and Filter Change]';

function SearchPage() {
  const [state, dispatch] = useFilterData();
  const [loadingState, loadingDispatch] = useKexLoadingCircle();

  // clean up
  useEffect(() => {
    return () => {
      loadingDispatch({
        type: 'remove',
        from: SEARCH_LOADING,
      });
    };
  }, [loadingDispatch]);

  const isMobile = useMedia(mediaQueryTypes.mediaMaxMedium);

  const {
    searchLabels: { loadMore, counter },
  } = useTranslationData();

  const [newQuery, setNewQuery] = useState('');

  const {
    staticPages: { searchPage },
  } = useAppSettingsData();

  const [activeTab, setActiveTab] = useState<SearchTypes>(SearchTypes.Products);
  const {
    result,
    noResult,
    isLoading,
    loadingMore,
    facets,
    sorters,
    paginate,
  } = useSearch(searchPage, state.queryParams);

  const stateLoading = !!loadingState.has(SEARCH_LOADING);

  useEffect(() => {
    if (isLoading && !stateLoading) {
      loadingDispatch({
        type: 'add',
        from: SEARCH_LOADING,
      });
    } else if (!isLoading && stateLoading) {
      loadingDispatch({
        type: 'remove',
        from: SEARCH_LOADING,
      });
    }
  }, [isLoading, stateLoading, loadingDispatch]);

  useEffect(() => {
    dispatch({ type: 'onClear' });
    setNewQuery(state.query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.query]);

  const searchResults =
    activeTab === SearchTypes.Products
      ? result?.productSearchResult
      : result?.contentSearchResult;

  useEffect(() => {
    if (facets && sorters) dispatch({ type: 'setFacets', facets, sorters });
  }, [facets, sorters, dispatch]);

  const loadedPercentage = useMemo(
    () =>
      searchResults
        ? (searchResults?.items.length / searchResults?.availableItems) * 100
        : 0,
    [searchResults]
  );

  const counterText = useMemo(() => {
    return searchResults
      ? counter
          .replace('{0}', searchResults?.items.length.toString())
          .replace('{1}', searchResults?.availableItems.toString())
      : '';
  }, [searchResults, counter]);

  useEffect(() => {
    const currentActiveTab = getUrlParameter('tab') as SearchTypes;
    if (currentActiveTab) {
      setActiveTab(currentActiveTab);
    }
  }, []);

  useEffect(() => {
    switch (activeTab) {
      case SearchTypes.Content:
        if (result && result.documentSearchResult?.availableItems === 0) {
          setActiveTab(SearchTypes.Products);
        }
        break;
      case SearchTypes.Products:
      default:
        break;
    }
  }, [result, activeTab, setActiveTab]);

  const hits = useCallback(() => {
    if (!result) return 0;
    return (
      (result.productSearchResult?.items.length || 0) +
      (result.categorySearchResult?.items.length || 0) +
      (result.documentSearchResult?.items.length || 0)
    );
  }, [result]);

  const doSearch = () => {
    if (newQuery.length > 1) {
      dispatch({ type: 'setQuery', value: newQuery });
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case SearchTypes.Products:
        return (
          <>
            <StyledGrid>
              {result?.productSearchResult?.items
                .filter(
                  (entry) => entry.productType !== ProductType.VENDORSPAREPARTS
                )
                .map((product: ProductCardModel, index) => (
                  <ProductCard key={index} item={product} />
                ))}
            </StyledGrid>

            {result?.productSearchResult?.items
              .filter(
                (entry) => entry.productType === ProductType.VENDORSPAREPARTS
              )
              .map((product: ProductCardModel, index) => (
                <VendorSparepartCard key={index} item={product} />
              ))}
          </>
        );
      case SearchTypes.Content:
        return (
          <>
            {result?.contentSearchResult?.items && (
              <>
                <ContentResult content={result?.contentSearchResult?.items} />
              </>
            )}
          </>
        );
    }
  };

  const disableButton = useCallback(() => {
    switch (activeTab) {
      case SearchTypes.Products:
        if (
          result?.productSearchResult?.items.length ===
          result?.productSearchResult?.availableItems
        ) {
          return true;
        }
        break;
      case SearchTypes.Content:
        if (
          result?.documentSearchResult?.items.length ===
          result?.documentSearchResult?.availableItems
        ) {
          return true;
        }
        break;
    }
    return false;
  }, [activeTab, result]);

  return (
    <>
      <ContentContainer>
        <TopContainer css={!state.query.length ? { display: 'none' } : {}}>
          {isMobile && (
            <SearchInput
              query={newQuery}
              setQuery={(value: string) => setNewQuery(value)}
              doSearch={doSearch}
              css={{ h: 12 }}
            />
          )}

          <FilterBar
            results={{
              facets: facets,
              productResults: result?.productSearchResult,
            }}
            query={state.query}
            counterText={counterText}
            contentResultAmount={result?.contentSearchResult?.availableItems}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
        </TopContainer>
      </ContentContainer>
      <LowerContainer>
        <ContentWrapper>
          {!isLoading ? (
            <ContentContainer>{renderTabContent()}</ContentContainer>
          ) : (
            <Padding />
          )}
        </ContentWrapper>

        {noResult && (
          <NoResult
            query={state.query}
            suggestions={result?.didYouMeanProposals || []}
            setQuery={(query) => dispatch({ type: 'setQuery', value: query })}
          />
        )}
        {hits() !== 0 && !isLoading && (
          <LoadMoreContainer
            loaded={loadedPercentage}
            counterText={counterText}
            loadMore={loadMore}
            disabled={disableButton()}
            onClick={() => {
              paginate(activeTab);
              dispatch({
                type: 'setLoadedItems',
                value: (result
                  ? result?.productSearchResult.items.length + 24
                  : 0
                ).toString(),
              });
            }}
            isLoading={loadingMore}
          />
        )}
      </LowerContainer>
    </>
  );
}

export default SearchWrapper;

const TopContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  backgroundColor: '$backgroundPrimary',
  pb: 12,
  pt: 16,
  borderBottomStyle: 'solid',
  borderBottomColor: '$searchPageBorderPrimary',
});

const LowerContainer = styled('div', {
  backgroundColor: '$searchBackgroundColor',
  pb: 10,
  w: '100%',
});

const ContentWrapper = styled('div', {
  py: 8,
  '@mediaMinLarge': {
    py: 12,
  },
});

const Padding = styled('div', {
  height: '400px',
});

const StyledGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '16px',
  maxWidth: 'auto',
  '@media(min-width: 768px)': {
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '32px',
  },
  '@mediaMinLarge': {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '32px',
  },
});

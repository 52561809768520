import { H3 } from 'Atoms/Typography/Headings/Heading';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';
import ContentModel from 'Models/Assets/ContentModel.interface';
import { styled } from 'stitches.config';
import { animation, timings } from 'Theme/Settings/animation';

type ContentResultType = {
  content: ContentModel[];
};

function ContentResult(props: ContentResultType) {
  const kexNavigate = useKexNavigate();

  return (
    <Container>
      {props.content.map((item, index) => (
        <Article
          key={item.name + index}
          onClick={() => {
            kexNavigate(item.src);
          }}
        >
          <H3 noMargin>{item.name}</H3>
          {item.introText && <IntroText>{item.introText}</IntroText>}
        </Article>
      ))}
    </Container>
  );
}

export default ContentResult;

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxW: 160,
  mx: 'auto',
});

const Article = styled('div', {
  w: '100%',
  cursor: 'pointer',
  p: 4,
  backgroundColor: '$primary3',
  transition: `all ${timings.oneFifth} ${animation.timingFn}`,
  '&:hover': {
    backgroundColor: '$primary2',
  },
  mb: 4,
  '@mediaMinLarge': {
    p: 6,
  },
});

const IntroText = styled('div', {
  fs: 8,
  ls: '$ls163',
  color: '$contentResultTextPrimary',
  mt: 2,
  textOverflow: 'ellipsis',
});

import Button from 'Atoms/Buttons/Button';
import QuantitySelector from 'Atoms/Quantity/QuantitySelector';
import ProductCardModel from 'Models/ProductCard/ProductCardModel.interface';
import { useState } from 'react';
import { SetQuantity } from 'Shared/Cart/Cart';
import useMedia from 'Shared/Hooks/useMedia';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';

type PropTypes = {
  item: ProductCardModel;
};

function VendorSparepartCard({ item }: PropTypes) {
  const { name, nowPrice, code } = item;
  const { favoritesLabels } = useTranslationData();
  const [quantity, setQuantity] = useState<number>(1);
  const [isShowQuantity, setIsShowQuantity] = useState<boolean>(false);
  const { languageRoute } = useAppSettingsData();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  return (
    <Card>
      <div>
        <ItemName>{name}</ItemName>
        <ItemCode>{code}</ItemCode>
      </div>
      <Right>
        <Price>{nowPrice.priceRoundedAsString}</Price>
        {isShowQuantity || isDesktop ? (
          <QuantitySelector
            code={item.code}
            maxQuantity={50}
            minQuantity={1}
            currentQuantity={quantity}
            handleIncrement={() => setQuantity(quantity + 1)}
            handleDecrement={() => setQuantity(quantity - 1)}
            size={isDesktop ? 'l' : 's'}
          />
        ) : (
          <CurrentQuantityWrapper onClick={() => setIsShowQuantity(true)}>
            {quantity}
          </CurrentQuantityWrapper>
        )}

        <Button
          type="primary"
          size={isDesktop ? 'l' : 's'}
          onClick={() => SetQuantity(item.code, quantity, languageRoute)}
          isLoading={false}
          disabled={false}
        >
          {favoritesLabels.addToCart}
        </Button>
      </Right>
    </Card>
  );
}

export default VendorSparepartCard;

const Card = styled('div', {
  display: 'flex',
  backgroundColor: '$primary2',
  flexDirection: 'column',
  p: '16px',
  '@mediaMinLarge': {
    flexDirection: 'row',
    px: '32px',
    py: '28px',
  },
});

const ItemName = styled('div', {
  fs: 9,
  fontFamily: '$fontSecondary600',
  lh: '$lh12',
  color: '$secondary2',
  '@mediaMinLarge': {},
});

const ItemCode = styled('div', {
  fs: 6,
  color: '$secondary2',
  '@mediaMinLarge': {},
});

const Right = styled('div', {
  display: 'flex',
  marginLeft: 'unset',
  g: 1,
  marginTop: '24px',
  '@mediaMinLarge': {
    marginTop: 'unset',
    marginLeft: 'auto',
  },
});

const Price = styled('div', {
  fontFamily: '$fontSecondary600',
  lh: '$lh12',
  color: '$secondary2',
  marginRight: 'auto',
  my: 'auto',
  '@mediaMinLarge': { fs: 10, marginRight: '64px' },
});

const CurrentQuantityWrapper = styled('button', {
  backgroundColor: 'rgba(255, 255, 255, 0.0263)',
  color: '$secondary2',
  lineHeight: '$lh125',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'default',
  w: 12,
  px: 6,
  py: 3,
});
